import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import {
  getCurAddr , getuserejoin
} from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";
import syncEach from "sync-each";
import polygonapi from "../ABI/matricbankABI";
import timestampapi from "../ABI/timestampapi";
import config from "../config/config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BigNumber from "bignumber.js";
import moment from "moment"
import Aboveinfo from "../components/aboveinfo.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard(props) {
  var count = 0;

  useEffect(() => {
    window.addEventListener("resize", larg);
  }, []);

  async function larg() {
    setwidth(window.innerWidth - 50);
    setheight(window.innerHeight - 50);
  }

  const [selected, setSelected] = useState({});
  const [allselected, setallSelected] = useState({});
  const [width, setwidth] = useState(window.innerWidth - 50);
  const [height, setheight] = useState(window.innerHeight - 50);
  const [userdetails, setuserdetails] = useState({});
  const [rejoindetails,setrejoindetails] = useState({});
  const [totaluser, settotaluser] = useState(0);
  const [useraddress, setaddress] = useState("");
  const [treeprogress, settreeprogress] = useState(false);
  const [referallist,setreferallist] = useState([]);
  const [progress, setprogress] = useState("false");
  const [level1, setlevel1] = useState([]);
  const [level2, setlevel2] = useState([]);
  const [level3, setlevel3] = useState([]);
  const [level4, setlevel4] = useState([]);
  const [selected_id, setselected_id] = useState("");
  const [rejoinlist,setrejoinlist] = useState([]);
  const [earnedamt,setearnedamt] = useState(0);


  useEffect(() => {
    getdetails();
  }, []);

  async function getdetails() {
    try {
      if (count == 0) {
        count = 1;
        let datas = await getCurAddr();
        if (
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != ""
        ) {
          if (datas.userdetails && datas.userdetails.isExist) {
            setuserdetails(datas.userdetails);
            setaddress(datas.address);
            let web3 = new Web3(
              datas &&
              datas.provider &&
              datas.provider != null &&
              datas.provider != undefined &&
              datas.provider != ""
                ? datas.provider
                : window.ethereum
            );
            getreferraldetails(datas);
            var polygonContract = new web3.eth.Contract(
              polygonapi,
              config.matricbankcontract
            );
            let usercount = await polygonContract.methods.totalUsers().call();
            settotaluser(usercount);
            let earne_amt = await polygonContract.methods.totalearnedAmount(datas.address).call();
            setearnedamt(earne_amt);
            let ids = await polygonContract.methods.userList(datas.address).call();
            let refer = await polygonContract.methods.viewallrefferalByID(ids).call();
            setrejoindetails(refer);
            let dats_input = {
              "address" : datas.address
            }
            let rect = await getuserejoin(dats_input);
            if(rect && rect.data && rect.data.data){
              setrejoinlist(rect.data.data);
            }
            
          } else {
            window.location.href = "/";
          }
        } else {
          window.location.href = "/";
        }
      }
    } catch (err) {
      console.log(err, "======eee==e=e=");
    }
  }

  async function getreferraldetails(datas){
    try{
      let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum );
      var polygonContract = new web3.eth.Contract( polygonapi, config.matricbankcontract );
      let users_id = await polygonContract.methods.userList(datas.address).call();
      let referal_list = await polygonContract.methods.viewallDirectAddress(datas.address).call();
      if(referal_list.length>0){
        let referal_rec = [];
        syncEach(referal_list, async function (items,next) {
          let user_id = await polygonContract.methods.userList(items).call();
          let user_details = await polygonContract.methods.users(user_id).call();
          let rec = {
            "address" :  items ,
            "id" : user_details.id,
          }
          referal_rec.push(rec);
          next();
        },function (errs,t_Items) {
          setreferallist(referal_rec);
        })
      }
      //**************************treework******************************//
      let downlinenode = await polygonContract.methods.viewallrefferalByID(users_id).call();
      let downlinenode1 = [];
      let downlinenode2 = [];
      let downlinenode3 = [];
      let downlinenode4 = [];
      let downline1arr  = [];
      let downline2arr  = [];
      let downline3arr  = [];
      let downline4arr  = [];

      if (downlinenode.length > 0) {
          for (let node in downlinenode) {
            let id1 = await polygonContract.methods.userList(downlinenode[node]).call();
            let userprof = await polygonContract.methods.users(id1).call();
            let downline1 = await polygonContract.methods.viewallrefferalByID(id1).call();
            let downline_1 = {
              "address": downlinenode[node],
              "userid": userprof.id,
              "downlinenode": downline1
            }
            downlinenode1.push(downline_1);
            downline1arr = downline1arr.concat(downline1);
          }
      }

      //********downline2*******//

      if (downline1arr.length > 0) {
          for (let node in downline1arr) {
            let id2 = await polygonContract.methods.userList(downline1arr[node]).call();
            let userprof = await polygonContract.methods.users(id2).call();
            let downline1 = await polygonContract.methods.viewallrefferalByID(id2).call();
            let downline_1 = {
              "address": downline1arr[node],
              "userid": userprof.id,
              "downlinenode": downline1
            }
            downlinenode2.push(downline_1);
            downline2arr = downline2arr.concat(downline1);
          }
      }

      //********downline3*******//

      if (downline2arr.length > 0) {
          for (let node in downline2arr) {
            let id3 = await polygonContract.methods.userList(downline2arr[node]).call();
            let userprof = await polygonContract.methods.users(id3).call();
            let downline1 = await polygonContract.methods.viewallrefferalByID(id3).call();
            let downline_1 = {
              "address": downline2arr[node],
              "userid": userprof.id,
              "downlinenode": downline1
            }
            downlinenode3.push(downline_1);
            downline3arr = downline3arr.concat(downline1);
          }
      }

      //********downline4*******//

      if (downline3arr.length > 0) {
          for (let node in downline3arr) {
            let id4 = await polygonContract.methods.userList(downline3arr[node]).call();
            let userprof = await polygonContract.methods.users(id4).call();
            let downline1 = await polygonContract.methods.viewallrefferalByID(id4).call();
            let downline_1 = {
              "address": downline3arr[node],
              "userid": userprof.id,
              "downlinenode": downline1
            }
            downlinenode4.push(downline_1);
            downline4arr = downline4arr.concat(downline1);
          }
      }
        let line1arr = [];
        if (downlinenode1.length > 0) {
          syncEach(downlinenode1, async function (items, next) {
            if (items && items.downlinenode && items.downlinenode.length > 0) {
              let subline = [];
              syncEach(items.downlinenode, async function (subitems, next1) {
                let IndexVal = downlinenode2.findIndex(val => val.address == subitems);
                if (IndexVal >= 0 && downlinenode2[IndexVal].downlinenode && downlinenode2[IndexVal].downlinenode.length > 0) {
                  let subline2 = [];
                  syncEach(downlinenode2[IndexVal].downlinenode, async function (thir_items, next2) {
                    let IndexVal1 = downlinenode3.findIndex(val => val.address == thir_items);
                    if (IndexVal1 >= 0 && downlinenode3[IndexVal1].downlinenode && downlinenode3[IndexVal1].downlinenode.length > 0) {
                      let subline3 = [];
                      syncEach(downlinenode3[IndexVal1].downlinenode, async function (fourth_items, next3) {
                        let Index_Val = downlinenode4.findIndex(val => val.address == fourth_items);
                        let user22 = {
                          name: "ID " + downlinenode4[Index_Val].userid 
                        }
                        subline3.push(user22);
                        next3();
                      }, function (error, success) {
                        let mainline = {
                          "name": "ID " + downlinenode3[IndexVal1].userid 
                        }
                        setlevel3(mainline);
                        subline2.push(mainline);
                        next2();
                      })
                    } else {
                      let IndexVal = downlinenode3.findIndex(val => val.address == thir_items);
                      let user1 = {
                        name: "ID " + downlinenode3[IndexVal].userid 
                      }
                      subline2.push(user1);
                      next2();
                    }
                    // let user12 = {
                    //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                    // }
                    // subline2.push(user12);
                    // next2();
                  }, function (error, success) {
                    let mainline = {
                      "name": "ID " + downlinenode2[IndexVal].userid ,
                      "children": subline2
                    }
                    setlevel2(mainline)
                    subline.push(mainline);
                    next1();
                  })
                } else {
                  var IndexValue = downlinenode2.findIndex(val => val.address == subitems);
                  let user1 = {
                    name: "ID " + downlinenode2[IndexValue].userid 
                  }
                  subline.push(user1);
                  next1();
                }
              }, function (errs, t_Items) {
                let mainline = {
                  "name": "ID " + items.userid ,
                  "children": subline
                }
                setlevel1(mainline)
                console.log("push33")
                line1arr.push(mainline);
                next();
              })
            } else {
              let mainline = {
                "name": "ID " + items.userid ,
                "children": []
              }
              setlevel1(mainline);
              setlevel2([])
              line1arr.push(mainline);
              next();
            }
          }, function (err, transformedItems) {
            let mainlinearr = {
              name: "ID " + datas.userdetails.id ,
              "children": line1arr
            }
            setSelected(mainlinearr);
            setallSelected(mainlinearr);
            settreeprogress(false)
          })
        } else {
          let mainlinearr = {
            name: "ID " + datas.userdetails.id ,
            "children": []
          }
          setSelected(mainlinearr);
          setallSelected(mainlinearr);
          settreeprogress(false);
        }

    }catch(err){
      console.log(err,"====eieieueieieie")
    }
  }

  async function re_join() {
    try{
      setprogress("true")
      let datas = await getCurAddr();
      if(datas && datas.address && 
            datas.address!=undefined && 
            datas.address!=null && 
            datas.address!="" && localStorage.getItem("account")){
        if(datas.userdetails && 
            datas.userdetails.isExist){
            let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
            var polygonContract = new web3.eth.Contract(polygonapi, config.matricbankcontract);
            let levelprice = await polygonContract.methods.JoinAmount().call();
            let ids = await polygonContract.methods.userList(datas.address).call();
            let refer = await polygonContract.methods.viewallrefferalByID(ids).call();
            if(refer && refer.length>=2){              
              let sendamt = await convert(levelprice);
              if(parseFloat(datas.polygonvalue)>parseFloat(levelprice)){
                let register = await polygonContract.methods.reJoin().send({
                    from: datas.address,
                    value: sendamt.toString()
                })
                if(register && register.status && register.transactionHash){
                  let loguser = await polygonContract.methods.users(datas.address).call();
                  setprogress("false")
                  toastAlert('success', "Re-Joined Successfully", 'network');
                  window.location.href="/dashboard"
                }else{
                  setprogress("false")
                  toastAlert('success', "Failed , try again later", 'network');
                }
              }else{
                setprogress("false")
                toastAlert('error', "Insufficient Balance", 'network');
              }
            }else{
              setprogress("false")
              toastAlert('error', "Not Eligible for Rejoin", 'network');
            }
        }else{
          setprogress("false")
          window.location.href="/";
        }
      }else{
        setprogress("false")
        toastAlert('error', "Please select Matic chain network", 'network');
      }
    }catch(err){
      console.log(err)
      setprogress("false")
    }
  }

  async function handleClick(event, node) {
    if (node && node != null && node != undefined && node != "") {
      let myArray = node.split(" ");
      let userid = myArray[1];
      if (selected_id == userid) {
        setSelected(allselected);
        setselected_id("");
      } else {
        settreeprogress(true)
        setselected_id(userid)
        let datas = await getCurAddr();
        let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
        var polygonContract = new web3.eth.Contract(polygonapi, config.matricbankcontract);
        console.log(userid,"===useriduseriduseridhablds")
         let downlinenode = await polygonContract.methods.viewallrefferalByID(userid).call();
      let downlinenode1 = [];
      let downlinenode2 = [];
      let downlinenode3 = [];
      let downlinenode4 = [];
      let downline1arr  = [];
      let downline2arr  = [];
      let downline3arr  = [];
      let downline4arr  = [];

      if (downlinenode.length > 0) {
          for (let node in downlinenode) {
            let id1 = await polygonContract.methods.userList(downlinenode[node]).call();
            let userprof = await polygonContract.methods.users(id1).call();
            let downline1 = await polygonContract.methods.viewallrefferalByID(id1).call();
            let downline_1 = {
              "address": downlinenode[node],
              "userid": userprof.id,
              "downlinenode": downline1
            }
            downlinenode1.push(downline_1);
            downline1arr = downline1arr.concat(downline1);
          }
      }

      //********downline2*******//

      if (downline1arr.length > 0) {
          for (let node in downline1arr) {
            let id2 = await polygonContract.methods.userList(downline1arr[node]).call();
            let userprof = await polygonContract.methods.users(id2).call();
            let downline1 = await polygonContract.methods.viewallrefferalByID(id2).call();
            let downline_1 = {
              "address": downline1arr[node],
              "userid": userprof.id,
              "downlinenode": downline1
            }
            downlinenode2.push(downline_1);
            downline2arr = downline2arr.concat(downline1);
          }
      }

      //********downline3*******//

      if (downline2arr.length > 0) {
          for (let node in downline2arr) {
            let id3 = await polygonContract.methods.userList(downline2arr[node]).call();
            let userprof = await polygonContract.methods.users(id3).call();
            let downline1 = await polygonContract.methods.viewallrefferalByID(id3).call();
            let downline_1 = {
              "address": downline2arr[node],
              "userid": userprof.id,
              "downlinenode": downline1
            }
            downlinenode3.push(downline_1);
            downline3arr = downline3arr.concat(downline1);
          }
      }

      //********downline4*******//

      if (downline3arr.length > 0) {
          for (let node in downline3arr) {
            let id4 = await polygonContract.methods.userList(downline3arr[node]).call();
            let userprof = await polygonContract.methods.users(id4).call();
            let downline1 = await polygonContract.methods.viewallrefferalByID(id4).call();
            let downline_1 = {
              "address": downline3arr[node],
              "userid": userprof.id,
              "downlinenode": downline1
            }
            downlinenode4.push(downline_1);
            downline4arr = downline4arr.concat(downline1);
          }
      }
        let line1arr = [];
        if (downlinenode1.length > 0) {
          syncEach(downlinenode1, async function (items, next) {
            if (items && items.downlinenode && items.downlinenode.length > 0) {
              let subline = [];
              syncEach(items.downlinenode, async function (subitems, next1) {
                let IndexVal = downlinenode2.findIndex(val => val.address == subitems);
                if (IndexVal >= 0 && downlinenode2[IndexVal].downlinenode && downlinenode2[IndexVal].downlinenode.length > 0) {
                  let subline2 = [];
                  syncEach(downlinenode2[IndexVal].downlinenode, async function (thir_items, next2) {
                    let IndexVal1 = downlinenode3.findIndex(val => val.address == thir_items);
                    if (IndexVal1 >= 0 && downlinenode3[IndexVal1].downlinenode && downlinenode3[IndexVal1].downlinenode.length > 0) {
                      let subline3 = [];
                      syncEach(downlinenode3[IndexVal1].downlinenode, async function (fourth_items, next3) {
                        let Index_Val = downlinenode4.findIndex(val => val.address == fourth_items);
                        let user22 = {
                          name: "ID " + downlinenode4[Index_Val].userid 
                        }
                        subline3.push(user22);
                        next3();
                      }, function (error, success) {
                        let mainline = {
                          "name": "ID " + downlinenode3[IndexVal1].userid 
                        }
                        setlevel3(mainline);
                        subline2.push(mainline);
                        next2();
                      })
                    } else {
                      let IndexVal = downlinenode3.findIndex(val => val.address == thir_items);
                      let user1 = {
                        name: "ID " + downlinenode3[IndexVal].userid 
                      }
                      subline2.push(user1);
                      next2();
                    }
                    // let user12 = {
                    //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                    // }
                    // subline2.push(user12);
                    // next2();
                  }, function (error, success) {
                    let mainline = {
                      "name": "ID " + downlinenode2[IndexVal].userid ,
                      "children": subline2
                    }
                    setlevel2(mainline)
                    subline.push(mainline);
                    next1();
                  })
                } else {
                  var IndexValue = downlinenode2.findIndex(val => val.address == subitems);
                  let user1 = {
                    name: "ID " + downlinenode2[IndexValue].userid 
                  }
                  subline.push(user1);
                  next1();
                }
              }, function (errs, t_Items) {
                let mainline = {
                  "name": "ID " + items.userid ,
                  "children": subline
                }
                setlevel1(mainline)
                console.log("push33")
                line1arr.push(mainline);
                next();
              })
            } else {
              let mainline = {
                "name": "ID " + items.userid ,
                "children": []
              }
              setlevel1(mainline);
              setlevel2([])
              line1arr.push(mainline);
              next();
            }
          }, function (err, transformedItems) {
            let mainlinearr = {
              name: "ID " + userid ,
              "children": line1arr
            }
            setSelected(mainlinearr);
            settreeprogress(false)
          })
        } else {
          let mainlinearr = {
            name: "ID " + userid ,
            "children": []
          }
          setSelected(mainlinearr);
          settreeprogress(false);
        }
      }
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.Front_URL + "join-now/" + useraddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  async function empty(){
    
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  var buy_item_carousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "1px",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++");
      return "";
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="dasbox">
              <div className="row">
                <div className="col-md-12">
                  <div className="title_flex">
                    <h2 className="inner_title">Dashboard</h2>
                    <div className="wallet_div">
                      <label>Affiliate Link:</label>
                      <div className="inp_grp">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          value={config.Front_URL + "join-now/" + useraddress}
                        />
                        <button
                          type="button"
                          className="round_btn primary_btn"
                          onClick={copyToClipboard}
                        >
                          <img
                            src={require("../assets/images/copy_icon.png")}
                            className="copy_icon"
                            alt="Copy"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard_top_wrap">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="leftbox">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {" "}
                                    {userdetails && userdetails.id
                                      ? userdetails.id
                                      : ""}
                                  </h2>
                                  <h4>My Present Id</h4>
                                 {rejoindetails && rejoindetails.length < 2 ? 
                                  <p className="act_ive green" >Active</p>
                                  :
                                  <p className="act_ive red" >InActive</p>
                                }
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_04.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>{totaluser ? totaluser : 0}</h2>
                                  <h4>All Participants</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_01.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {earnedamt &&
                                    parseFloat(earnedamt) > 0
                                      ? (
                                          parseFloat(
                                            earnedamt
                                          ) / 1e18
                                        ).toFixed(5)
                                      : 0}
                                  </h2>
                                  <h4>Total Earned Matic</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_02.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {referallist.length}
                                  </h2>
                                  <h4>Direct Referral</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_03.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {referallist.length
                                      ? (
                                          parseFloat(
                                            referallist.length
                                          ) *0.0005
                                        ).toFixed(5)
                                      : 0}
                                  </h2>
                                  <h4>Referal Earned Matic</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_02.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <div className="rightbox">
                      <div className="dashbox_middle_bottom slider">
                        <div className="box">
                          <div className="row flx_align">
                            <div className="col-lg-12 text-center">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="dashbox_middle_bottom">
                                    <h5>Total Income</h5>
                                    <h2 className="mb-0">
                                      {earnedamt
                                        ? parseFloat(earnedamt) /1e18 
                                        : 0}{" "}
                                      <span>Matic </span>{" "}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="butn rejoin_btn"
                            type="button"
                            onClick={progress=="false" ? ()=>re_join() : ()=>empty()}
                          >
                            {progress=="false" ?"Rejoin" : "Processing.. Please Wait"}
                          </button>
                        </div>
                      </div>

                      <div className="dashbox_middle_bottom">
                        <h2>History Data</h2>
                        <div className="plan_income_table_div mt-1">
                          <div className="table-responsive">
                            <table className="table plan_income_table mt-0">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Amount Earned</th>
                                  <th>Rebirth Count</th>
                                  <th>Hash</th>
                                </tr>
                              </thead>
                              <tbody>
                              {rejoinlist && rejoinlist.length>0 && (rejoinlist).map((items,index)=>{
                                return(
                                  <tr>
                                    <td>{items && items.date && moment(items.date).format('MMMM-Do-YYYY, h:mm a')}</td>
                                    <td>70</td>
                                    <td>{parseInt(rejoinlist.length)-index}</td>
                                    <td>{items && items.trx}</td>
                                  </tr>
                                )
                              })}
                              {rejoinlist && rejoinlist.length<=0 &&
                                <p>No Record Found</p>
                              }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="dashbox_middle_bottom mt-5">
                        <h2>Genealogy Tree</h2>
                        <div className="genealogoy_box">
                          {!treeprogress ? (
                            <div>
                              <AnimatedTree
                                data={selected}
                                height={1200}
                                width={800}
                                gProps={{
                                  className: "node",
                                  onClick: handleClick,
                                }}
                                nodeShape="image"
                                nodeProps={{
                                  href:
                                    config.Front_URL + "images/tree_icon.png",
                                  width: "13",
                                  height: "21",
                                }}
                                margins={{
                                  top: 50,
                                  bottom: 50,
                                  left: 80,
                                  right: 80,
                                }}
                                textProps={{ dx: -16, dy: 25 }}
                              />
                            </div>
                          ) : (
                            <div>
                              <center>
                                <p className="text-danger mb-1">
                                  Processing....,Please Wait
                                </p>
                              </center>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="dashbox_middle_bottom mt-5">
                        <h2>Referral data</h2>
                        <div className="plan_income_table_div mt-1">
                          <div className="table-responsive">
                            <table className="table plan_income_table mt-0">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>User ID</th>
                                  <th>Wallet Address</th>
                                </tr>
                              </thead>
                              <tbody>
                              {referallist && referallist.length>0 && (referallist).map((item, index) => {
                                return(
                                  <tr>
                                    <td>{parseFloat(index)+1}</td>
                                    <td>{item && item.id ?  item.id : ""}</td>
                                    <td>{item && item.address ? item && item.address : ""}</td>
                                  </tr>
                                )
                              })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
