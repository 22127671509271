import axios from 'axios';
import config from '../config/config';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import polygonapi from "../ABI/matricbankABI";


export const getCurAddr = async () => {
  if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="trust"){
    try{
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
         rpc: {
          [config.chainId]: config.rpcURL
        },
        chainId: config.chainId

      });

      provider.on("connect", () => {
      });
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if(result[0]!=undefined){
        if (config.chainId  == network) {
          localStorage.setItem("wallettype","trust")
          localStorage.setItem("account",result[0]);
          let polygonbalance = await web3.eth.getBalance(result[0]);
          console.log('polygonbalancwwwwwwwwwwwwwwwwwwwwwe: ', polygonbalance);
          var polygonContract = new web3.eth.Contract(polygonapi, config.matricbankcontract);
          let ids = await polygonContract.methods.userList(result[0]).call();
          let userdetails = await polygonContract.methods.users(ids).call();
          console.log('userdetailsaaaaaa: ', userdetails);
          var data = {
             address : result[0],
             provider : provider,
             polygonvalue  : polygonbalance,
             userdetails : userdetails
          }
          return data
        } else {
         await provider.disconnect();
        }
     }
    }catch(err){
      localStorage.clear();
      window.location.reload()
    }
  }
  else{
   if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum.enable()
          let web3 = new Web3(window.ethereum);
          if (window.ethereum) {
            let netid = await web3.eth.net.getId();
            console.log(netid,"==========getCurAddrgetCurAddrgetCurAddr")
            if (
              netid ==
              config.networkVersion
            ) {
              var result = await web3.eth.getAccounts();
              let polygonbalance = await web3.eth.getBalance(result[0]);
              var polygonContract = new web3.eth.Contract(polygonapi, config.matricbankcontract);
              let ids = await polygonContract.methods.userList(result[0]).call();
              let userdetails = await polygonContract.methods.users(ids).call();
              if(localStorage.getItem("account")){
                localStorage.setItem("wallettype","metamask")
                localStorage.setItem("account",result[0]);
              }
              var data = {
                 address : result[0],
                 polygonvalue  : polygonbalance,
                 userdetails : userdetails
              }
              console.log(data,"======daa")
              return data 
            }
          }
        }
      }catch(err){
        localStorage.clear();
        console.log(err,"===========errrrrrrrrrrrdata")
     }
    }
  }
}

export const get_lastesttrx = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getlasttransaction`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const getuserejoin = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getuserejoin`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const getHeaderdetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getHeaderdetails`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}