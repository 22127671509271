import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import config from "../config/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { getCurAddr } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import logo from "../assets/images/logo.svg";

export default function Navbar(props) {
  const [selected, setSelected] = useState("GB");
  const [account, setaccount] = useState("");
  const [data, setdata] = useState({});
  const [status, setstatus] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    loadScript();
    getdetails();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }

  async function getdetails() {
    setstatus(true);
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      setdata(datas);
    }
  }

  async function connectTrustWallet() {
    try {
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          [config.chainId]: config.rpcURL,
        },
        chainId: config.chainId,
      });
      provider.on("connect", () => {});
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (result[0] != undefined) {
        console.log(network, "======network");
        if (config.chainId == network) {
          localStorage.setItem("wallettype", "trust");
          localStorage.setItem("account", result[0]);
          window.location.reload();
        } else {
          await provider.disconnect();
          toastAlert("error", "please select Matic chain network", "network");
        }
      }
    } catch (err) {
      console.log(err, "++++");
      toastAlert("error", "Error Occured Please Try again", "network");
      localStorage.clear();
      // window.location.href="/";
    }
  }

  async function connectClick() {
    try {
      const web3 = new Web3(window.ethereum);
      const result = await web3.eth.getAccounts();
      web3.eth.net.getId().then((res) => {
        if (result[0] != undefined) {
          if (res === config.networkVersion) {
            toastAlert("success", "Connected Wallet", "excerciseerr");
            setaccount(result[0]);
            localStorage.setItem("account", result[0]);
            localStorage.setItem("wallettype", "metamask");
            window.location.reload();
          } else {
            toastAlert("error", "Connect Matic Network", "excerciseerr");
            setaccount("");
            return false;
          }
        }
      });
    } catch (err) {}
  }

  window.addEventListener("load", async (event) => {
    if (
      localStorage.getItem("wallettype") &&
      localStorage.getItem("wallettype") == "metamask"
    ) {
      event.preventDefault();
      var provider = window.ethereum;
      var web3 = new Web3(provider);
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", async function (accounts) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts();
            var setacc = result[0];
            localStorage.setItem("account", result[0]);
            localStorage.setItem("wallettype", "metamask");
            window.location.href = "/";
          }, 1000);
        });

        window.ethereum.on("networkChanged", async function (networkId) {
          var provider = window.ethereum;
          var web3 = new Web3(provider);
          if (networkId == config.networkversion) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(async () => {
              var result = await web3.eth.getAccounts();
              var setacc = result[0];
              localStorage.setItem("account", result[0]);
              localStorage.setItem("wallettype", "metamask");
              props.Set_Accounts(setacc);
              window.location.href = "/";
            }, 1000);
          } else {
            localStorage.clear();
            window.location.href = "/";
          }
        });
      }
    }
  });

  async function logout() {
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-xl main_navbar">
        <div className="container">
          {data &&
          localStorage.getItem("account") &&
          data.address &&
          data.address != undefined &&
          data.address != null &&
          data.address != "" &&
          data.userdetails &&
          data.userdetails.isExist ? (
            <Link className="navbar-brand" to="/">
              <img src={logo} className="img-fluid brand_logo" alt="logo" />
            </Link>
          ) : (
            <Link className="navbar-brand" to="/">
              <img src={logo} className="img-fluid brand_logo" alt="logo" />
            </Link>
          )}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* <div className="ms-auto ms-2 navbar_right whitepaper">
                  <a
                    href={require("../assets/pdf/whitepaper.pdf")}
                    target="_blank"
                    className="butn"
                  >
                    white paper
                  </a>
                </div> */}
                <ul className="navbar_secondary">
                  <li>
                    <a href="../assets/pdf/whitepaper.pdf" target="_blank" className="butn_secondary">
                      whitepaper
                    </a>
                  </li>
                  <li>
                    <a href={config.matricurl + "address/" + config.matricbankcontract} target="_blank" className="butn_secondary">
                      Contract
                    </a>
                  </li>
                 {/* <li>
                    <a href="#" target="_blank" className="butn_secondary">
                      Audit
                    </a>
                  </li> */}
                </ul>
                {data &&
                localStorage.getItem("account") &&
                data.userdetails &&
                data.userdetails.isExist == false ? (
                  <div className="ms-3   navbar_right">
                    <div className="dropdown me-3">
                      <a
                        className="butn dropdown-toggle address_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={require("../assets/images/wallet_imgbtn.png")}
                          className="img-fluid addr_icon me-2"
                          lt="Icon"
                        />
                        {data.address}
                      </a>
                      <ul
                        className="dropdown-menu walletdrop "
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>Matic Balance:</label>
                            <span className="ms-2">
                              {data &&
                              data.polygonvalue &&
                              parseFloat(data.polygonvalue) > 0
                                ? (
                                    parseFloat(data.polygonvalue) / 1e18
                                  ).toFixed(4)
                                : 0}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button
                      type="button"
                      className="butn me-3 d-flex align-items-center joinnowbtn"
                      onClick={() => (window.location.href = "/join-now")}
                    >
                      <img
                        src={require("../assets/images/members.png")}
                        className="img-fluid wallet_icon me-2"
                        alt="Icon"
                      />{" "}
                      Join Now
                    </button>
                    <button
                      className="butn d-flex align-items-center"
                      onClick={() => logout()}
                    >
                      <img
                        src={require("../assets/images/logout_btn.png")}
                        className="img-fluid wallet_icon me-2"
                        alt="Logout"
                      />{" "}
                      Logout
                    </button>
                  </div>
                ) : data &&
                  localStorage.getItem("account") &&
                  data.address &&
                  data.address != undefined &&
                  data.address != null &&
                  data.address != "" &&
                  data.userdetails &&
                  data.userdetails.isExist ? (
                  <div className="ms-3  navbar_right">
                    <div className="dropdown me-3">
                      <a
                        className="butn dropdown-toggle address_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={require("../assets/images/wallet_imgbtn.png")}
                          className="img-fluid addr_icon me-2"
                          lt="Icon"
                        />
                        {data.address}
                      </a>
                      <ul
                        className="dropdown-menu walletdrop "
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>Matic Balance:</label>
                            <span className="ms-2">
                              {" "}
                              {data &&
                              data.polygonvalue &&
                              parseFloat(data.polygonvalue) > 0
                                ? (
                                    parseFloat(data.polygonvalue) / 1e18
                                  ).toFixed(4)
                                : 0}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/dashboard">
                            Dashboard
                          </a>
                        </li>
                      </ul>
                    </div>
                    <button
                      className="butn d-flex align-items-center"
                      onClick={() => logout()}
                    >
                      <img
                        src={require("../assets/images/logout_btn.png")}
                        className="img-fluid wallet_icon me-2"
                        alt="Logout"
                      />{" "}
                      Logout
                    </button>
                  </div>
                ) : (
                  status && (
                    <div className="ms-3 navbar_right">
                      <button
                        className="butn"
                        data-bs-toggle="modal"
                        data-bs-target="#connect_wallet_modal"
                      >
                        Connect Wallet
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={() => connectClick()}
                    >
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={() => connectTrustWallet()}
                    >
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
