import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import Web3 from "web3";
import moment from "moment";
import { getCurAddr  , get_lastesttrx } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import config from "../config/config";
import Aboveinfo from "../components/aboveinfo.js";
import { Scrollbars } from "react-custom-scrollbars";
import polygonapi from "../ABI/matricbankABI";
import timestampapi from "../ABI/timestampapi";
import $ from "jquery";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    $(document).ready(function () {
      $(".accordion-button").click(function () {
        $(".accordion-item").removeClass("active");
        $(this).closest(".accordion-item").addClass("active");
      });
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const [data, setdata] = useState({});
  const [latesttrx, setlatesttrx] = useState([]);

  useEffect(() => {
    getdetails();
  }, []);

  async function getdetails(){
   let record = await get_lastesttrx();
   if(record && record.data && record.data.data){
     setlatesttrx(record.data.data);
   }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <Navbar />
      <div className="page_header">
        <div className="ct-particle-animate">
          <div id="ct_particle_animate-a461fd71" className="shape-animate1">
            <img
              width="76"
              height="76"
              src={require("../assets/images/banner_icon_01.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd72" className="shape-animate2">
            <img
              width="91"
              height="91"
              src={require("../assets/images/banner_icon_02.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd73" className="shape-animate3">
            <img
              width="77"
              height="76"
              src={require("../assets/images/banner_icon_03.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd74" className="shape-animate4">
            <img
              width="91"
              height="91"
              src={require("../assets/images/banner_icon_04.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd75" className="shape-animate5">
            <img
              width="76"
              height="76"
              src={require("../assets/images/banner_icon_01.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd77" className="shape-left-right">
            <img
              width="91"
              height="91"
              src={require("../assets/images/banner_icon_02.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd76" className="shape-right-left">
            <img
              width="77"
              height="76"
              src={require("../assets/images/banner_icon_03.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 mx-auto text-center">
              <ScrollAnimation animateIn="slideInUp">
                <img
                  src={require("../assets/images/banner_bg.png")}
                  className="img-fluid"
                  alt="Binance"
                />
                <h1>Decentralized Global Fund Sharing on Smart Contract.</h1>
                <h2>Earn Instant 140% Profit</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="slideInUp">
                <h5>
                  Global cryptocurrency money-making system using an algorithm of instant profit distribution on smart contracts. Enjoy quick and easy profit with instant 140% returns - paid to your wallet Instant. Start today and become one of the first to discover the unlimited earning potential of Matic Bank.
                </h5>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        {/* Plan Income Section */}
        <section className="income_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-9 m-auto">
                <ScrollAnimation animateIn="zoomIn">
                  <div className="head">
                    <h2 className="h2tag">Your Daily Earnings Estimations</h2>
                  </div>
                  <div className="plan_income_table_div">
                    <div className="table-responsive">
                      <table className="table plan_income_table">
                        <thead>
                          <tr>
                            <th>YOUR JOINS</th>
                            <th>MATIC PROFIT</th>
                            <th>INCOME MATIC</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>10 times</td>
                            <td>20</td>
                            <td>200 MATIC</td>
                          </tr>
                          <tr>
                            <td>50 times</td>
                            <td>20</td>
                            <td>1000 MATIC</td>
                          </tr>
                          <tr>
                            <td>100 times</td>
                            <td>20</td>
                            <td>2000 MATIC</td>
                          </tr>
                          <tr>
                            <td>500 times</td>
                            <td>20</td>
                            <td>10000 MATIC</td>
                          </tr>
                          <tr>
                            <td>1000 times</td>
                            <td>20</td>
                            <td>20000 MATIC</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* Platform recent activity */}
        <section className="platform_activity">
          <div className="container">
            <div className="head">
              <ScrollAnimation animateIn="zoomIn">
                <h2 className="h2tag">Platform recent activity</h2>
                <p>Real-time global events of the Matic Bank Platform</p>
              </ScrollAnimation>
            </div>

            <ScrollAnimation animateIn="zoomIn">
              <div className="activity_box">
                <Scrollbars style={{ width: "100%", height: 450 }}>
                  <div className="activity_scroll">
                    {latesttrx &&
                      latesttrx.length > 0 &&
                      latesttrx.map((item) => {
                        return (
                          <div className="activity_box_flx">
                            <div>
                              <div className="flxbox">
                                <div>
                                  <img
                                    src={require("../assets/images/recent_act_logo.png")}
                                    className="img-fluid"
                                    alt="img"
                                  />
                                </div>
                                  <div>
                                    <img
                                      src={require("../assets/images/user_prf.png")}
                                      className="img-fluid"
                                      alt="img"
                                    />
                                  </div>
                                
                                {item.event == "regUser" && (
                                  <div>
                                    <p className="violet">New user joined</p>
                                  </div>
                                )}
                                {item.event == "regJoin" && (
                                  <div>
                                    <p className="violet"> ReJoin</p>
                                  </div>
                                )}
                                <div>
                                  <div className="btn">ID {item.userid}</div>
                                </div>
                              </div>
                            </div>
                            <div className="rightbtn">
                              <p>
                                <span>
                                  {" "}
                                  <a
                                    href={config.matricurl + "tx/" + item.trx}
                                    target="_blank"
                                  >
                                    <img
                                      src={require("../assets/images/arrow.png")}
                                      className="img-fluid me-2"
                                      alt="img"
                                    />
                                  </a>
                                </span>
                                <span>
                                  {" "}
                                  {item.hours > 0 ? item.hours : item.min}{" "}
                                  {item.hours > 0 ? " Hours" : " Min"}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Scrollbars>
              </div>
            </ScrollAnimation>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how_works_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ScrollAnimation animateIn="zoomIn">
                  <h2 className="h2tag text-center">How it Works</h2>
                  <div className="row">
                    <div className="col-md-12 col-lg-6 text-center">
                      <img
                        src={require("../assets/images/how_work.png")}
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="box">
                        <h3>Referral Income</h3>
                        <p>For each and every referral, you will get 10% as referral commission i.e., 5 MATIC as direct referral income. Once your referred ID keep on investing you will keep on receiving 10% referral income 
                        </p>
                      </div>
                      <div className="box">
                        <h3> Instant 140% Income </h3>
                        <p>
                          As Soon as You Refer 2 members under you. You will Get Instant 140% (70 MATIC) .And Your Id will be deactivated, again you have to rejoin for next 140% profit and refer 2 .
                        </p>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-8 m-auto">
                <ScrollAnimation animateIn="zoomIn">
                  <h2 className="h2tag text-center">FAQ</h2>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="accordion" id="faq_accodian">
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is MATICBANK.IO?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            Maticpay.io is an online crypto-decentralized ecosystem provides a platform where people are able to exchange wealth
                            </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How do I participate?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                             Join Maticbank.io Community by Transferring 50 MATIC To Maticbank.io Contract Address and earn Instant 140% profit once you complete your level with 2 IDs.      
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          I don't have a DAPP wallet. How can I create?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            Just download Trust wallet or Metamask wallet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                        >
                          I don't have MATIC Where can I get it?
                        </button>
                      </h2>
                      <div
                        id="collapse4"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingfour"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            You can purchase from binance.com are any centralized crypto exchanges.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsefive"
                          aria-expanded="false"
                          aria-controls="collapsefive"
                        >
                          How to change the address of the MATIC wallet?
                        </button>
                      </h2>
                      <div
                        id="collapsefive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingfive"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            You cannot change it, it’s totally decentralized system and does not have an administrator.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingsix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsesix"
                          aria-expanded="false"
                          aria-controls="collapsesix"
                        >
                          How much can I earn with an investment of 50 MATIC?
                        </button>
                      </h2>
                      <div
                        id="collapsesix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingsix"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                              It depends upon you and your team effort.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingseven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseseven"
                          aria-expanded="false"
                          aria-controls="collapseseven"
                        >
                          How long does it take to withdraw MATIC?
                        </button>
                      </h2>
                      <div
                        id="collapseseven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingseven"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                              All the payment systems connected with your upline and downline contract addresses on the blockchain, System will automatically transfer the rewards instantly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingeight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseeight"
                          aria-expanded="false"
                          aria-controls="collapseeight"
                        >
                          How long does it take to activate the ID?
                        </button>
                      </h2>
                      <div
                        id="collapseeight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingeight"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            It’s on blockchain smart contract activation that will happen in seconds.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingnine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsenine"
                          aria-expanded="false"
                          aria-controls="collapsenine"
                        >
                          Matic Bank account has validity?
                        </button>
                      </h2>
                      <div
                        id="collapsenine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingnine"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            Yes, your ID will be deactivated once you complete your level 1 with 2 members. Again you have to join with 50 Matic .
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingten">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseten"
                          aria-expanded="false"
                          aria-controls="collapseten"
                        >
                          Does it allow having multiple accounts?
                        </button>
                      </h2>
                      <div
                        id="collapseten"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingten"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                              It will only create one wallet address and one account, Yes, you can have multiple wallet addresses and can create multiple accounts.
                          </p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>

                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
