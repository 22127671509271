import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import { getCurAddr } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import polygonapi from "../ABI/matricbankABI";
import polygonpayapi from "../ABI/polygonpayABI";
import Aboveinfo from '../components/aboveinfo.js';
import config from '../config/config';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function JoinNow(props) {
  var { id } = useParams();
  const [data,setdata] = useState({});
  const [referaladdress,setreferaladdress] = useState("");
  const [progress, setprogress] = useState("false");

  useEffect(() => {
    getdetails();
    checkreferal();
  }, []);

  const referralchange = (e) =>{
    setreferaladdress(e.target.value);
  }

  async function checkreferal(){
    if(id && id!=null && id!=undefined && id!=""){
      setreferaladdress(id);
    }
  }

  async function getdetails(){
   let datas = await getCurAddr(); 
   if(datas && datas.address && 
          datas.address!=undefined && 
          datas.address!=null && 
          datas.address!="" && localStorage.getItem("account")){
       setdata(datas);
       if(datas.userdetails && 
          datas.userdetails.isExist){
          window.location.href="/dashboard"
       }else{
          
       }
   }
  }

  async function empty(){

  }

  async function joinnow(){
    try{
      setprogress("true")
      let datas = await getCurAddr();
      console.log(datas,"=====injoinnow")
      if(datas && datas.address && 
            datas.address!=undefined && 
            datas.address!=null && 
            datas.address!="" && localStorage.getItem("account")){
        if(datas.userdetails && 
            datas.userdetails.isExist){
          setprogress("false")
          toastAlert('error', "Already Joined", 'network');
          window.location.href="/dashboard";
        }else{
         if(referaladdress.toLowerCase()!=(datas.address).toLowerCase()){
           let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
           if(web3.utils.isAddress(referaladdress)){
            var polygonpayContract = new web3.eth.Contract(polygonpayapi, config.polygonpaycontract);
            let refexist = await polygonpayContract.methods.users(referaladdress).call();
            if(refexist && refexist.isExist ){
            var polygonContract = new web3.eth.Contract(polygonapi, config.matricbankcontract);
            let users_id = await polygonContract.methods.userList(referaladdress).call();
            let userdetails = await polygonContract.methods.users(users_id).call();
            if(userdetails.isExist){
              let levelprice = await polygonContract.methods.JoinAmount().call();
              let sendamt = await convert(levelprice);
              if(parseFloat(datas.polygonvalue)>parseFloat(levelprice)){
                let register = await polygonContract.methods.regUser(referaladdress).send({
                    from: datas.address,
                    value: sendamt.toString()
                })
                if(register && register.status && register.transactionHash){
                  let loguser = await polygonContract.methods.users(datas.address).call();
                  setprogress("false")
                  toastAlert('success', "Joined Successfully", 'network');
                  window.location.href="/dashboard"
                }else{
                  setprogress("false")
                  toastAlert('success', "Failed , try again later", 'network');
                }
              }else{
                setprogress("false")
                toastAlert('error', "Low Balance", 'network');
              }
            }else{
              setprogress("false")
              toastAlert('error', "Referral user not exist", 'network');
            }
            }else{
              setprogress("false")
              toastAlert('error', "Referral User need to participate in PolygonPay", 'network');
            }
           }else{
              if(id && id!=null && id!=undefined && id!=""){
                setprogress("false")
                toastAlert('error', "Invalid Referral Link", 'network');
              }else{
                setprogress("false")
                toastAlert('error', "Please Enter valid Address", 'network');
              }
           }
         }else{
           setprogress("false")
           toastAlert('error', "Your Wallet and Referral both are same", 'network');
         }
        }
      }else{
        setprogress("false")
        toastAlert('error', "Please select Matic chain network", 'network');
      }
    }catch(err){
      console.log(err)
      setprogress("false")
    }
  }

  function convert(n){
    var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead,decimal,pow] = n.toString()
     .replace(/^-/,"")
     .replace(/^([0-9]+)(e.*)/,"$1.$2")
     .split(/e|\./);
     return +pow < 0
    ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
    : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.rootaddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Join Now</h2>
                  <div className="mt-4">
                    <div className="form-group row">
                      <div className="col-lg-6 m-auto text-start">
                        <label for="exampleFormControlInput1" class="form-label">Referral ID</label>
                        <div className="copy_admin_flx" >
                        <input type="text" value={referaladdress} class="form-control primary_inp" id="exampleFormControlInput11" placeholder="Enter referral Address" onChange={referralchange} disabled={id && id!=undefined && id!=null && id!="" ?"disabled":""}/>
                        </div>
                        <button type="button" className="butn" onClick={progress=="false"?()=>joinnow():()=>empty()}>{progress=="false"?"Submit":"Processing..Please Wait.."}</button>
                      </div>
                    </div>                    
                  </div>                  
                </div>
              </div>
            </div>           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
