import React from "react";
import config from "../config/config";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_left">
          <p>Smart-contract address: </p>
            <a
              href={config.matricurl + "address/" + config.matricbankcontract}
              target="_blank"
            >
              {config.matricbankcontract}
            </a>
            <p>
              Copy right ©{new Date().getFullYear()} MaticBank, All rights
              Reserved
            </p>
          </div>
          <div className="footer_panel_right">
            <ul className="footer_social_links">
              <li>
                <a href="#" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              {/* <li><a href="/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>*              
              <li><a href="#" target="_blank"><i class="fab fa-telegram-plane"></i></a></li>
              <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
              <li><a href="#" target="_blank"><i class="fab fa-whatsapp"></i></a></li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
