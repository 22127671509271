import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import config from "../config/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { getCurAddr } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import logo from "../assets/images/logo.svg"

export default function Navbar(props) {
  const [selected, setSelected] = useState('GB');
  const [account, setaccount] = useState('');
  const [data, setdata] = useState({});
  const [status, setstatus] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    loadScript();
    getdetails();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }

  async function getdetails() {
    let datas = await getCurAddr();
    setstatus(true);
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" && localStorage.getItem("account")) {
      setdata(datas);
    }
  }

  async function connectTrustWallet() {
    try {
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
         rpc: {
          [config.chainId]: config.rpcURL
        },
        chainId: config.chainId

      });

      provider.on("connect", () => {
      });
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (result[0] != undefined) {
        if (config.chainId == network) {
          localStorage.setItem("wallettype", "trust")
          localStorage.setItem("account", result[0]);
          window.location.reload();
        } else {
          await provider.disconnect();
          toastAlert('error', "please select Matic chain network", 'network');
        }
      }
    } catch (err) {
      toastAlert('error', "Error Occured Please Try again", 'network');
      localStorage.clear();
      window.location.href="/";
    }
  }

  async function connectClick() {
    try {
      const web3 = new Web3(window.ethereum);
      const result = await web3.eth.getAccounts();
      web3.eth.net.getId().then(res => {
        if (result[0] != undefined) {
          if (res === config.networkVersion) {
            toastAlert('success', "Connected Wallet", 'excerciseerr');
            setaccount(result[0]);
            localStorage.setItem('account', result[0]);
            localStorage.setItem('wallettype', "metamask");
            window.location.reload();
          } else {
            toastAlert('error', "Connect Matic Network", 'excerciseerr');
            setaccount("")
            return false;
          }
        }
      });
    } catch (err) {
    }
  }

  window.addEventListener('load', async (event) => {
    if (localStorage.getItem("wallettype") && localStorage.getItem("wallettype") == "metamask") {
      event.preventDefault();
      var provider = window.ethereum;
      var web3 = new Web3(provider);
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', async function (accounts) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts()
            var setacc = result[0];
            localStorage.setItem("account", result[0])
            localStorage.setItem('wallettype', "metamask");
            window.location.href="/";
          }, 1000);
        })

        window.ethereum.on('networkChanged', async function (networkId) {
          var provider = window.ethereum;
          var web3 = new Web3(provider);
          if (networkId == config.networkversion) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(async () => {
              var result = await web3.eth.getAccounts()
              var setacc = result[0];
              localStorage.setItem("account", result[0])
              localStorage.setItem('wallettype', "metamask");
              props.Set_Accounts(setacc);
              window.location.href="/";
            }, 1000);
          }
          else {
            localStorage.clear()
            window.location.href="/";
          }
        })
      }
    }
  })

  async function logout() {
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light main_navbar main_navbar_inner">
        <div className="container">
          {/* {data && localStorage.getItem("account") && data.address && data.address != undefined && data.address != null && data.address != "" && data.userdetails && data.userdetails.isExist ? */}
            <Link className="navbar-brand" to="/">
              <img src={logo} className='img-fluid brand_logo' alt='logo' />
            </Link>
            {/* : */}
             {/* <Link className="navbar-brand" to="/">
               <img src={require("../assets/images/logo.svg")} className='img-fluid brand_logo' alt='logo' />
             </Link> */}
          {/* } */}
        </div>
      </nav>

    </>
  );
}