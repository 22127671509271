import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";

import Home from './pages/home.js';
// import Dashboardold from './pages/dashboardold.js';
import Dashboard from "./pages/dashboard.js";
import JoinNow from './pages/join-now';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Terms from "./pages/terms.js";

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    // { path: "dashboardold", element: <Dashboardold /> },
    { path: "dashboard", element: <Dashboard /> },
    { path: "join-now", element: <JoinNow /> },
    { path: "join-now/:id", element: <JoinNow /> },
    { path: "terms", element: <Terms /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="">
      <ToastContainer />
      <App />
    </Router>
  );
};

export default AppWrapper;