import React ,{ useState, useEffect } from 'react';
import $ from "jquery";
import { getHeaderdetails } from "../action/Apicontroller";

export default function Aboveinfo(props) {

    const[details,setdetails]  = useState({});

    useEffect(() => {
        loadScript1();
        getdetails();
      }, []);
    
      function loadScript1() {
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();
    
          if (scroll >= 100) {
            $(".above_info_box").addClass("above_fixed");
          } else {
            $(".above_info_box").removeClass("above_fixed");
          }
        });
      }

    async function getdetails(){
      let record = await getHeaderdetails();
     if(record && record.data && record.data.data){
       setdetails(record.data.data);
     }
    }
    
   return (
    <section className="above_info_box">
      <div className="container">
        <div className='above_box_flx'>
          <div>
            <p>All participants</p>
            <span>{details && details.total_user ? details.total_user : 0 }</span>
          </div>
          <div>
            <p>Joined in 24 hours </p>
            <span>{details && details.users24 ? parseInt(details.users24)+parseInt(1) : 0 }</span>
          </div>
          <div>
            <p>Profit users result </p>
            <span>{details && details.tot_prof ? details.tot_prof : 0 }</span>
          </div>
        </div>
      </div>
    </section>
  );  
}