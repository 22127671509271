// let NODE_ENV      = 'production';
let NODE_ENV = 'demo';


let Front_URL = '';
let Base_URL = '';
let rpcURL = '';
let decimalvalues = 1000000000000000000;
let toFixed = 4;
let networkVersion = '';
let matricurl = '';
let adminAddress = ""
let matricbankcontract = "";
let polygonpaycontract = "";
let chainId = ""
let Server_URL = "";
let livechainid = "0x137";
let rootaddress = "";
let timestampcontract = "";

let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

if (NODE_ENV === "production") {
}
else if (NODE_ENV === "demo") {
    Front_URL = 'https://matic-bank.pages.dev/';
    Server_URL = 'https://maticbank.wearedev.team/';
    networkVersion = 97;
    rpcURL = 'https://data-seed-prebsc-1-s1.binance.org:8545';
    matricurl = "https://testnet.bscscan.com/";
    adminAddress = "0x373F886904048975034488320E37d171E092C135";
    rootaddress = "0x373f886904048975034488320e37d171e092c135";
    matricbankcontract = "0x5D3e2f57e1FC0d68c193E38226b67dd230F06ABc";
    polygonpaycontract = "0x1B0d309eC77C5AFC499556E70654665019833C2d";
    chainId = 97;
}
else {
    Front_URL = 'http://localhost:3000/';
    Server_URL = 'http://localhost:3076/';
    networkVersion = 97;
    rpcURL = 'https://matic-mumbai.chainstacklabs.com';
    matricurl = "https://mumbai.polygonscan.com/";
    adminAddress = "0x373F886904048975034488320E37d171E092C135";
    rootaddress = "0x373F886904048975034488320E37d171E092C135";
    matricbankcontract = "0x5D3e2f57e1FC0d68c193E38226b67dd230F06ABc";
    polygonpaycontract = "0x1B0d309eC77C5AFC499556E70654665019833C2d";
    chainId = 97;
}

let key = {
    Front_URL: Front_URL,
    Server_URL: Server_URL,
    decimalvalues: decimalvalues,
    toFixed: toFixed,
    "rpcURL": rpcURL,
    networkVersion: networkVersion,
    toasterOption: toasterOption,
    adminAddress: adminAddress,
    rootaddress: rootaddress,
    matricbankcontract: matricbankcontract,
    polygonpaycontract: polygonpaycontract,
    timestampcontract: timestampcontract,
    matricurl: matricurl,
    chainId: chainId,
    livechainid : livechainid
};

export default key;
